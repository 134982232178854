import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import { Section } from 'components/Section'
import { Text } from 'components/Typography'
import { Flex, Box } from 'components/Grid'
import NonStretchedImage from 'components/NonStretchedImage'
import Content, { HTMLContent } from 'components/Content'

import styled, { themePx, themeGet } from 'util/style'

const StyledDate = styled(Box)`
  font-size: ${themePx('fontSizes.0')};
  font-weight: 600;
  color: ${themeGet('colors.lightGrey')};
  text-transform: uppercase;
`

const Project = ({ data, contentComponent }) => {
  const { excerpt, frontmatter } = data
  const { title, image, date } = frontmatter
  const PostContent = contentComponent || Content

  return (
    <Flex mb={5} flexDirection={['column', 'row']}>
      <Box width={[1, 1 / 4]}>
        <NonStretchedImage fluid={image.childImageSharp.fluid} alt={title} />
      </Box>
      <Box width={[1, 3 / 4]} pl={[0, 4]} mt={[2, 0]}>
        <Text mb={2} fontWeight={700}>
          {title}
        </Text>
        <StyledDate>{date}</StyledDate>
        <PostContent mt={2} content={excerpt || data.html} />
      </Box>
    </Flex>
  )
}

Project.propTypes = {
  data: PropTypes.object.isRequired,
  contentComponent: PropTypes.func.isRequired,
}

export const ProjectsIndexPageTemplate = ({ title, data }) => (
  <Section title={title}>
    <Box>
      {data &&
        data.map(project => (
          <Project
            key={project.id}
            data={project}
            contentComponent={HTMLContent}
          />
        ))}
    </Box>
  </Section>
)

ProjectsIndexPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

const ProjectsIndexPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const { footerData = {}, headerData = {} } = data

  const projects = edges.map(edge => edge.node)
  const title = 'Проекти'

  let hData = {}
  if (headerData.edges && headerData.edges.length > 0) {
    hData = headerData.edges[0].node.frontmatter
  }

  let fData = {}
  if (footerData.edges && footerData.edges.length > 0) {
    fData = footerData.edges[0].node.frontmatter
  }

  return (
    <Layout headerData={hData} footerData={fData}>
      <SEO title={title} />
      <ProjectsIndexPageTemplate title={title} data={projects} />
    </Layout>
  )
}

ProjectsIndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProjectsIndexPage

export const pageQuery = graphql`
  query ProjectsIndexPageTemplate {
    ...LayoutFragment
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "project" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 500, format: HTML)
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
            date(formatString: "MMMM DD, YYYY")
            description
            tags
          }
        }
      }
    }
  }
`
